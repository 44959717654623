import React from "react";
import "./Splash.css";

function Splash() {
  return (
    <div className="splash">
      {/* Add your splash screen content here */}
      <img src="./default.svg" />
    </div>
  );
}

export default Splash;
