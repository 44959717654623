import workData from './work_data.json';
import projectsData from './projects_data.json';
import parse from 'html-react-parser';

export default function WorkExperienceAndProjects() {
    return (
        <div className="resume-main col-8">
            <section className="work-section">
                <h3 className="text-uppercase resume-section-heading mb-3">Work Experiences</h3>

                {workData.map((item) => {
                    return (
                        <div key={item.companyName} className="item mb-3">
                            <div className="item-heading row align-items-start mb-1">
                                <h4 className="item-title col-12 col-md-8 col-lg-8 mb-2 mb-md-0" style={{ flex: '0 0 70%', maxWidth: '70%' }}>
                                    <a rel="noreferrer" target='_blank' href={item.companyLink}>{item.companyName}</a>
                                    <span className="job-title"> | {item.positionTitle} </span>
                                    {item.workType && <span className="job-location text-muted">({item.workType})</span>}
                                    <div className="experience-technologies">Technologies: {item.technologies}</div>
                                </h4>
                                <div className="item-meta col-12 col-md-4 col-lg-4 text-muted text-left text-md-right" style={{ flex: '0 0 30%', maxWidth: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <a rel="noreferrer" target={'_blank'} href={item.companyLink}><span className="job-location">
                                        <img className="job-image" src={item.workImage} alt={item.companyName} />
                                        </span></a>
                                    {item.period}
                                </div>
                            </div>
                            <div className="item-content">
                                <ul className="resume-list">
                                    {item.workdescription.map((desc) => {
                                        return (
                                            <li key={desc}>{parse(desc)}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </section>


            <section className="project-section py-2">
                <h3 className="text-uppercase resume-section-heading mb-3">Projects</h3>

                {projectsData.map((project) => {
                    return (
                        <div key={project.projectTitle} className="item mb-3">
                            <div className="item-heading row align-items-start mb-1">
                                <h4 className="item-title col-12 col-md-8 col-lg-8 mb-2 mb-md-0" style={{ flex: '0 0 70%', maxWidth: '70%' }}>
                                    <a rel="noreferrer" target={'_blank'} href={project.projectLink}>{project.projectTitle}</a>
                                    <span className="job-title"> | {project.positionTitle} </span>
                                    {project.workType && <span className="job-location text-muted">({project.workType})</span>}
                                    <div className="experience-technologies">Technologies: {project.technologies}</div>
                                </h4>
                                <div className="item-meta col-12 col-md-4 col-lg-4 text-muted text-left text-md-right" style={{ flex: '0 0 30%', maxWidth: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span className="job-location"><img className="job-image" src={project.projectImage} alt={project.projectTitle} /></span>
                                    {project.period}
                                </div>
                            </div>
                            <div className="item-content">
                                <ul className="resume-list">
                                    {project.projectDescription.map((desc) => {
                                        return (
                                            <li key={desc}>{parse(desc)}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}